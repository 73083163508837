<template>
  <div class="d-flex flex-column">
    <!-- #region AddPopup -->
    <template>
      <div id="app-container">
        <dx-util-popup
          :visible="addPopupVisible"
          :hide-on-outside-click="true"
          :show-title="true"
          :drag-enabled="true"
          :width="720"
          height="auto"
          position="center"
          title="Add System Data"
        >
          <dx-util-scroll-view>
            <div class="printermanagementmodal">
              <div class="printermanagement-container">
                <dx-util-form
                  id="form"
                  :form-data="addfordata"
                  label-mode="static"
                  :read-only="false"
                  :show-colon-after-label="true"
                  label-location="left"
                  :min-col-width="300"
                  col-count="1"
                />
              </div>
              <div class="printermanagement-footer">
                <dx-util-button
                  class="savebutton"
                  text="Save"
                  @click="save()"
                />
                <dx-util-button
                  class="cancelbutton"
                  text="Close"
                  @click="closeAddPopup()"
                />
              </div>
            </div>
          </dx-util-scroll-view>
        </dx-util-popup>
      </div>
    </template>
    <!-- #endregion -->

    <!-- #region EditPopup -->
    <template>
      <div id="app-container">
        <dx-util-popup
          :visible="editPopupVisible"
          :hide-on-outside-click="true"
          :show-title="true"
          :drag-enabled="true"
          :width="720"
          height="auto"
          position="center"
          title="Edit System Data"
        >
          <dx-util-scroll-view>
            <div class="printermanagementmodal">
              <div class="printermanagement-container">
                <dx-util-form
                  id="form"
                  :form-data="editfordata"
                  label-mode="static"
                  :read-only="false"
                  :show-colon-after-label="true"
                  label-location="left"
                  :min-col-width="300"
                  col-count="1"
                />
              </div>
              <div class="printermanagement-footer">
                <dx-util-button
                  class="savebutton"
                  text="Edit"
                  @click="editLocal()"
                />
                <dx-util-button
                  class="cancelbutton"
                  text="Close"
                  @click="closeEditPopup()"
                />
              </div>
            </div>
          </dx-util-scroll-view>
        </dx-util-popup>
      </div>
    </template>
    <!-- #endregion -->

    <!-- #region DataGrid -->
    <template id="datagrid">
      <dx-data-grid
        ref="dataGrid"
        :data-source="SystemDataModelDataSource"
        key-expr="id"
        :show-borders="true"
        class="mt-2"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-sorting mode="single" />
        <dx-remote-operations
          :paging="true"
          :sorting="false"
          :filtering="false"
        />
        <!-- Id -->
        <dx-column
          data-field="id"
          :width="100"
        />
        <!-- Key -->
        <dx-column
          data-field="key"
        />
        <!-- Value -->
        <dx-column
          data-field="value"
        />
        <!-- Buttons -->
        <dx-column
          cell-template="selectionCellTemplate"
          :width="65"
        />
        <template #selectionCellTemplate="{ data }">
          <div>
            <button
              class="devextreme-edit-button"
              @click="editAction(data)"
            >
              <feather-icon
                class="devextreme-cell-color-orange"
                icon="Edit2Icon"
              />
            </button>
            <button
              class="devextreme-remove-button"
              @click="deleteAction(data)"
            >
              <feather-icon
                class="devextreme-cell-color-red"
                icon="TrashIcon"
              />
            </button>
          </div>
        </template>
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
      </dx-data-grid>
    </template>
    <!-- #endregion -->
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { Notify } from '@robustshell/utils/index'
import printerserverapi from '@/http/requests/printer/printerserver'
import { AddSystemData } from '@/http/models/printer/entity/SystemData/addSystemData'
import { EditSystemData } from '@/http/models/printer/entity/SystemData/editSystemData'
import { SystemDataModelDataSource } from './system-data'

export default {
  name: 'PrinterSystemData',
  components: {
  },
  directives: {
  },
  mixins: [GridBase],
  data() {
    return {
      addPopupVisible: false,
      addSystemData: new AddSystemData(),
      addfordata: {
        key: '',
        value: '',
      },
      editPopupVisible: false,
      editSystemData: new EditSystemData(),
      editid: 0,
      editfordata: {
        key: '',
        value: '',
      },
      SystemDataModelDataSource,
    }
  },
  mounted() {
    this.loadSystemData()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.openAddPopup()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.refreshTable()
          },
        },
        location: 'after',
      })
    },
    openAddPopup() {
      if (this.addPopupVisible === true) { this.addPopupVisible = false }
      setTimeout(() => {
        this.addPopupVisible = true
      }, 100)
    },
    closeAddPopup() {
      this.addPopupVisible = false
    },
    save() {
      // #region Validation
      this.addSystemData.key = this.addfordata.key
      this.addSystemData.value = this.addfordata.value
      // #endregion
      printerserverapi.addSystemData(this.addSystemData).then(result => {
        if (result.body === null) {
          Notify.error('Printer system data could not create')
        } else {
          Notify.success('Printer system data created')
          this.addPopupVisible = false
          this.loadSystemData()
          this.clearAddData()
        }
      })
    },
    clearAddData() {
      this.addfordata = {
        key: '',
        value: '',
      }
    },
    editAction(e) {
      if (this.editPopupVisible === true) { this.editPopupVisible = false }
      setTimeout(() => {
        this.editPopupVisible = true
      }, 100)
      this.editid = e.row.data.id
      this.editfordata.key = e.row.data.key
      this.editfordata.value = e.row.data.value
    },
    editLocal() {
      if (this.editid === 0) {
        Notify.error('Unexpected model')
        return
      }
      this.editSystemData.key = this.editfordata.key
      this.editSystemData.value = this.editfordata.value
      //
      printerserverapi.editSystemData(this.editid, this.editSystemData).then(result => {
        if (result.body === null) {
          Notify.error('Printer system data could not update')
        } else {
          Notify.success('Printer system data updated')
          this.editPopupVisible = false
          this.loadSystemData()
          this.clearEditData()
        }
      })
    },
    clearEditData() {
      this.editid = 0
      this.editfordata = {
        key: '',
        value: '',
      }
    },
    closeEditPopup() {
      this.editPopupVisible = false
    },
    refreshTable() {
      this.$refs.dataGrid.instance.state(null)
    },
    loadSystemData() {
      SystemDataModelDataSource.reload()
    },
    deleteAction(e) {
      const { id } = e.row.data
      this.$bvModal
        .msgBoxConfirm(`Are you sure to delete this system data? ${e.row.data.key}`, {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if (value === true) {
            printerserverapi.deleteSystemData(id).then(result => {
              if (result.body === null) {
                Notify.error('Printer system data could not delete')
              } else {
                Notify.success('Printer system data deleted')
                this.loadSystemData()
              }
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '../../../../assets/scss/printermanagement/printermanagement.scss';
</style>
